import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.webp";
import { useSelector, shallowEqual, useDispatch } from "react-redux";


export default function MenuLogo() {
   const isMobileState = useSelector(
      state => state.mainReducer.isMobile,
      shallowEqual
    );
  return (
<>


   {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" || "/landingpage/gameart/" || "/landingpage/masters-and-bachelors-game-development-courses/" || "/landingpage/masters-and-bachelors-programs/" || window.location.pathname === "/landingpage/game-art-programs/" || window.location.pathname === "/landingpage/short-course/" || window.location.pathname === "/landingpage/game-development-with-unity-programs/" || window.location.pathname === "/landingpage/best-gaming-courses-in-india/"  ?   <Link to="/" className="mainLogo"><img
        src={logo}
        alt="Backstage Pass"
        className="logo"
        height={isMobileState ? "43" : "50"} 
              width={isMobileState ? "250" : "250"} 
      /> </Link>:
    <Link to="/" className="mainLogo">
      <img
      height={isMobileState ? "43" : "50"} 
      width={isMobileState ? "250" : "250"} 
        src={logo}
        alt="Backstage Pass"
        className="logo"
        
      />
    </Link>}
    </>
  );
}

